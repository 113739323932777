<template>
  <transition name="fade" mode="out-in">
    <div v-if="!publicCompanyProfile" class="page__loading" key="loading">
      <AppLoading/>
    </div>
    <div class="dashboard-page" v-else>
      <div class="dashboard-page__row">
        <div class="dashboard-page__row--left">
          <ProfilePanel v-if="publicCompanyProfile" :profile="publicCompanyProfile" :editable="false"/>
        </div>
        <div class="dashboard-page__row--right">
          <ProfileAbout v-if="publicCompanyProfile"
                        :profile="publicCompanyProfile"
                        :coverImage="publicCompanyProfile.cover_image ? publicCompanyProfile.cover_image.src_lg : null"
                        :editable="false"/>
          <ProfileReviews v-if="publicCompanyRatings" :ratings="publicCompanyRatings"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import ProfilePanel from './profile-company/ProfilePanel';
import ProfileAbout from '@/components/profile-company/ProfileAbout';
import ProfileReviews from '@/components/profile-company/ProfileReviews';
import AppLoading from './AppLoading';

export default {
  components: {
    ProfilePanel,
    ProfileAbout,
    ProfileReviews,
    AppLoading,
  },
  data() {
    return {
      uuid: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      publicCompanyProfile: state => state.companyProfile.publicCompanyProfile,
      publicCompanyRatings: state => state.companyProfile.publicCompanyRatings,
    }),
  },
  mounted() {
    if (this.$route.params.uuid) {
      this.loading = true;
      this.uuid = this.$route.params.uuid;
      this.$store.dispatch('companyProfile/getPublicCompany', this.uuid)
        .then((res) => {
          // console.log(res);
        })
        .catch(err => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>
