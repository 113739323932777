<template>
  <transition name="fade" mode="out-in">
    <div class="dashboard-page">
      <div class="dashboard-page__row">
        <div class="dashboard-page__row--left">
          <ProfilePanel v-if="user" :profile="user" :notPublic="sameUser"/>
          <ResumeSkillsPanel v-if="user && user.skills" :resumeSkills="user.skills"/>
          <ResumeAddressPanel v-if="user && user.userActiveResume && user.userActiveResume.address" :address="user.userActiveResume.address"/>
          <ResumeLanguagesPanel v-if="user && user.userActiveResume && user.userActiveResume.languages" :languages="user.userActiveResume.languages"/>
          <ResumeEducationPanel v-if="user && user.userActiveResume && user.userActiveResume.education" :education="user.userActiveResume.education"/>
          <ResumeHobbiesPanel v-if="!sameUser && user && user.userActiveResume && user.userActiveResume.hobbies" :hobbies="user.userActiveResume.hobbies"/>
        </div>
        <div class="dashboard-page__row--right">
          <ResumeCoverPanel v-if="user && user" :profile="user" :coverImage="user.cover_image ? user.cover_image.src_lg : null"/>
          <apexchart v-show="user && user.availabilities" type="bar" height="350" :options="chartOptions" :series="series" ref="chart"></apexchart>
          <ResumeBioPanel v-if="user && user.userActiveResume && user.userActiveResume.bio" :notPublic="true" :bio="user.userActiveResume.bio"/>
          <ResumeWorkExperiencePanel v-if="user && user.userActiveResume && user.userActiveResume.work_experiences" :workExperience="user.userActiveResume.work_experiences"/>
          <ResumeExperiencePanel v-if="user && user.userActiveResume && user.userActiveResume.experience" :notPublic="true" :experience="user.userActiveResume.experience"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import ProfilePanel from './profile-neoworqer/ProfilePanel';
import ResumeLanguagesPanel from './profile-neoworqer/ResumeLanguagesPanel';
import ResumeEducationPanel from './profile-neoworqer/ResumeEducationPanel';
import ResumeHobbiesPanel from './profile-neoworqer/ResumeHobbiesPanel';
import ResumeExperiencePanel from './profile-neoworqer/ResumeExperiencePanel';
import ResumeWorkExperiencePanel from './profile-neoworqer/ResumeWorkExperiencePanel';
import ResumeBioPanel from './profile-neoworqer/ResumeBioPanel';
import ResumeAddressPanel from './profile-neoworqer/ResumeAddressPanel';
import ResumeCoverPanel from './profile-neoworqer/ResumeCoverPanel';
import ResumeSkillsPanel from './profile-neoworqer/ResumeSkillsPanel';
import { mobileAndTabletCheck } from '@/utils/helpers';

export default {
  i18nOptions: { namespaces: 'dashboard-public-resume' },
  components: {
    ProfilePanel,
    ResumeLanguagesPanel,
    ResumeEducationPanel,
    ResumeHobbiesPanel,
    ResumeExperiencePanel,
    ResumeWorkExperiencePanel,
    ResumeBioPanel,
    ResumeAddressPanel,
    ResumeCoverPanel,
    ResumeSkillsPanel,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      user: null,
      uuid: null,
      loading: false,
      isMobile: mobileAndTabletCheck(),
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        // colors: ['#3c78df', '#1d63db', '#155ad0', '#0c4dbd', '#10459f'],
        colors: ['#ABBCEA', '#92B4E3', '#7BA4DD', '#628DD6', '#4C74C9'],
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          max: 100,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            // eslint-disable-next-line func-names
            formatter: function (val) {
              return `${val}%`;
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile.profile,
      language: state => state.language.lang,
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    sameUser() {
      if (this.profile && this.user) {
        return this.profile.uuid === this.user.uuid;
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    language: function (newVal, oldVal) {
      if (newVal && oldVal) {
        setTimeout(() => {
          this.updateAvailabilityChart();
        }, 0);
      }
    },
  },
  mounted() {
    if (this.$route.params.uuid) {
      this.loaded = true;
      this.uuid = this.$route.params.uuid;
      this.loading = true;

      this.$store.dispatch('search/getUser', this.uuid)
        .then((res) => {
          this.user = res;
          this.updateAvailabilityChart();
        })
        .catch(err => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    updateAvailabilityChart() {
      const firstWeekData = [];
      const secondWeekData = [];
      const thirdWeekData = [];
      const fourthWeekData = [];
      const fifthWeekData = [];
      const labels = [];

      let currentMonth = '';

      // eslint-disable-next-line array-callback-return
      this.user.availabilities.map((availability) => {
        if (currentMonth !== '' && currentMonth !== availability.month && firstWeekData.length > fifthWeekData.length) {
          fifthWeekData.push(0);
        }
        currentMonth = availability.month;
        switch (availability.week) {
          case 1:
            firstWeekData.push(availability.value);
            labels.push(`${this.$t(availability.month)} ${availability.year}`);
            break;
          case 2:
            secondWeekData.push(availability.value);
            break;
          case 3:
            thirdWeekData.push(availability.value);
            break;
          case 4:
            fourthWeekData.push(availability.value);
            break;
          case 5:
            fifthWeekData.push(availability.value);
            break;
          default:
            break;
        }
      });

      this.$refs.chart.updateSeries([{
        name: this.$t('week-1'),
        data: firstWeekData,
      }, {
        name: this.$t('week-2'),
        data: secondWeekData,
      }, {
        name: this.$t('week-3'),
        data: thirdWeekData,
      },
      {
        name: this.$t('week-4'),
        data: fourthWeekData,
      },
      {
        name: this.$t('week-5'),
        data: fifthWeekData,
      }]);

      this.$refs.chart.updateOptions({
        xaxis: {
          categories: labels,
        },
        yaxis: {
          max: 100,
          title: {
            text: this.$t('availability-overview'),
          },
        },
      });
    },
  },
};
</script>
